import React from 'react'
import styled, { css } from 'styled-components'
import { graphql, useStaticQuery, navigate } from 'gatsby'

// Libraries
import { motion, AnimatePresence } from 'framer-motion'

// Images
import Chevron from 'img/roundchevron.inline.svg'

// Elements
import ParseContent from 'components/shared/ParseContent'
import BlogBink, { useBlogBink, BlogBinkPosts } from '@ubo/blog-bink'
import ButtonSecondary from 'components/elements/Buttons/ButtonSecondary'

interface HighlightedQuestionsProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_PostTypes
}

const HighlightedQuestions: React.FC<HighlightedQuestionsProps> = ({
  fields,
}) => {
  const {
    allWpVraag,
    // eslint-disable-next-line
  } = useStaticQuery<GatsbyTypes.highlightedQuestionsQueryQuery>(graphql`
    query highlightedQuestionsQuery {
      allWpVraag(sort: { order: ASC, fields: date }) {
        edges {
          node {
            ...generalVraagFragment
          }
        }
      }
    }
  `)

  const posts: unknown = allWpVraag.edges
  const postIds = fields.posts?.map((post) => post?.databaseId)

  return (
    <section>
      <BlogBink
        showIds={
          // @ts-ignore
          fields.questions?.map(({ databaseId }) => databaseId) as Array<number>
        }
        posts={posts as BlogBinkPosts}
        id="highlightedQuestions"
        limit={Infinity}
      >
        <div>
          <BlogGrid fields={fields} />
        </div>
      </BlogBink>
    </section>
  )
}

interface BlogGridProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_PostTypes
}

const Title = styled(ParseContent)`
  & h2 {
    font-size: 35px;
    line-height: 40px;
  }

  @media (max-width: 991px) {
    & h2 {
      font-size: 24px;
      line-height: 28px;
    }
  }
`

const BlogGrid: React.FC<BlogGridProps> = ({ fields }) => {
  const blogBink = useBlogBink()

  return (
    <>
      <div className="container">
        <Title content={fields.title || ''} />
        <div className="row justify-content-center mt-lg-4 mt-2 g-3 d-flex">
          {blogBink.posts.map((post) => {
            const { node }: any = post

            return (
              <div key={post.node.id} className="col-lg-9">
                <BlogGridPost node={node} />
              </div>
            )
          })}
          <div className="col-12 mt-lg-5 mt-3 d-flex justify-content-center">
            {/* @ts-ignore */}
            <ButtonSecondary to="/veelgestelde-vragen/">
              {fields.loadmoretext}
            </ButtonSecondary>
          </div>
        </div>
      </div>
    </>
  )
}

interface BlogGridPostProps {
  node: GatsbyTypes.WpVraag
}

const StyledBlogGridPost = styled(motion.div)`
  background-color: ${({ theme }) => theme.color.grey};
  position: relative;
  border-radius: 15px;
  overflow: hidden;
  cursor: pointer;
`

const Question = styled.div<{ open: boolean }>`
  padding: 20px 35px;
  z-index: 3;
  transition: all 0.2s ease-in-out;

  & > div {
    color: ${({ theme }) => theme.color.dark};
    font-size: 18px;
    font-weight: ${({ theme }) => theme.font.weight.bold};
  }

  & svg {
    transition: all 0.2s ease-out;
    max-width: 32px;
    max-height: 32px;
    min-width: 32px;
    min-height: 32px;
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }

  ${({ open }) =>
    open
      ? css`
          & svg {
            transform: rotate(90deg);
          }
        `
      : css`
          & svg {
            transform: rotate(0deg);
          }
        `}

  @media (max-width: 575px) {
    padding: 15px 15px;

    & svg {
      height: 60px;
      width: 60px;
    }
  }
`

const Answer = styled(motion.div)`
  cursor: default;
`

const InnerAnswer = styled.div`
  padding: 20px 35px 20px 35px;
`

const BlogGridPost: React.FC<BlogGridPostProps> = ({ node }) => {
  const [open, setOpen] = React.useState(false)

  return (
    <StyledBlogGridPost initial="init">
      <Question
        open={open}
        onClick={() => setOpen(!open)}
        className="d-flex justify-content-between align-items-center"
      >
        <div className="me-sm-0 me-2">{node.faq?.question}</div>
        <Chevron />
      </Question>
      <AnimatePresence>
        {open && (
          <Answer
            initial={{ height: 0 }}
            animate={{ height: 'min-content' }}
            exit={{ height: 0 }}
          >
            <InnerAnswer>
              <ParseContent content={node.faq?.answer || ''} />
            </InnerAnswer>
          </Answer>
        )}
      </AnimatePresence>
    </StyledBlogGridPost>
  )
}

export default HighlightedQuestions
